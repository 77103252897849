

import React, { Component } from "react";

function CartItem({ items, handleClick, lang, currency }) {
  return (
    <div style={{ marginTop: 30 }}>
      {items !== undefined &&
        items !== null &&
        Object.keys(items).map(id => (
          <div className="row shopping--cart--item" style={ lang == 'en' ? { direction: "ltr" } : { direction: "rtl" }} data-aos="fade-up">
            <div className="col-sm-2">
              <div className="cart--item--img">
                <img
                  src={items[id].image}
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-sm-5">
              <div
               style={lang == "ar" ? {justifyContent: "start",
               direction: "rtl",
               display: "flex",  marginTop: 10, marginBottom: 10 } : {   direction: "ltr", marginTop: 10, marginBottom: 10}}
                className="basket--item--title"
              >
                <span>{items[id].name}</span>
              </div>
              <div
                className="basket--item--quantity"
                style={lang == "ar" ? {justifyContent: "start",
                direction: "rtl",
                display: "flex",  marginTop: 10, marginBottom: 10 } : {   direction: "ltr", marginTop: 10, marginBottom: 10}}
              >
               <span>{lang == "en" ? '  Quantity: ' : ' الكمية: '}</span>
                <span style={{ fontWeight: 'bold', margin: "0 10px 0 10px" }}>{items[id].count}</span>
              </div>
              <div
                className="basket--item--quantity"
                style={lang == "ar" ? {justifyContent: "start",
                direction: "rtl",
                display: "flex",  marginTop: 10, marginBottom: 10 } : {   direction: "ltr", marginTop: 10, marginBottom: 10}}
              >
               <span>{lang == "en" ? ' Color: ' : ' اللون: '}</span>
               <span className='ml-2' 
                style={{ width: '20px',
                height: '20px',
                display: 'inline-block',
                borderRadius: '20px', 
                margin: "0 10px 0 10px",
                backgroundColor: items[id].color, border: '1px solid black' }}></span>
              </div>
              <div
                className="basket--item--price"
                style={lang == "ar" ? {justifyContent: "start",
                direction: "rtl",
                display: "flex",  marginTop: 10, marginBottom: 10 } : {   direction: "ltr", marginTop: 10, marginBottom: 10}}
              >
               <span>{lang == "en" ? ' Price: ' : ' السعر: '}</span>
                 <span style={{ margin: "0 10px 0 10px", fontWeight: 'bold' }} >
                  {currency == "dollar" ? `$${items[id].price}` : `${(items[id].price * 250.27).toFixed(2)} YRI`}
                 </span>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
}

export default CartItem;
