import {
  SET_LANG,
  GET_LANG
} from "../actions/langAction";

const initialState = {
  lang: "en",
  loading: false,
  error: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_LANG:
      console.log("action", action.payload);
      return {
        ...state,
        lang: action.payload,
      };
    case GET_LANG:
      return {
        ...state,
      };
    default:
      return state;
  }
};