

import React, { Component } from "react";
import LoginRegister from "../../components/LoginRegisterModal";
import Auth from "../../modules/Auth";
import { BASE_URL } from "../../base";
import ProductService from "../../axios/ProductService";
import { toast } from "react-toastify";

class SingleProduct extends Component {
  constructor(props) {
    console.log("props", props);
    super(props);
    this.state = {
      color: "",
      size: "",
      pic: "",
      selectedSize: "",
      id: "",
      quantity: 1,
      modalShow: false,
      login: true,
      fav: false,
      currency: this.props.currency.currency
    };
    this.addToFav = this.addToFav.bind(this);
  }
  componentDidMount() {
    console.log(this.props);
    if(!this.props.product)
    {
      this.props.getProduct(this.props.location.pathname.split("/").slice(-1)[0]);
      this.props.getVariantsByProductId(
      this.props.location.pathname.split("/").slice(-1)[0]);
    }
  }
  componentDidUpdate(prevProps, prevState) {
    console.log('props', this.props);
  }

  showHideModal = () => {
    this.setState({ modalShow: false });
  };

  loginClicked = () => {
    this.setState({ modalShow: true, login: true });
  };
  registerClicked = () => {
    this.setState({ modalShow: true, login: false });
  };
  addToFav = (id) => {
    ProductService.addToFavorite(id)
    .then(resp => {
      this.setState({ fav: true })
      toast.success(resp)
    }).catch(er => console.error(er))
  }
  handleThumbnailClick = (item) => {
    this.setState({
      color: item.color,
      size: item.size,
      pic: item.imagePath,
      selectedSize: "",
      id: item._id,
      cartItem: null,
    });
  };

  onAddClicked = () => {
    this.setState({ quantity: this.state.quantity + 1 });
    this.props.postCart(
      this.state.id || this.props.location.pathname.split("/").slice(-1)[0],
      true,
      false
    );
  };
  onRemoveClicked = () => {
    this.props.postCart(
      this.state.id || this.props.location.pathname.split("/").slice(-1)[0],
      false,
      true
    );
    if (this.state.quantity > 1) {
      this.setState({ quantity: this.state.quantity - 1 });
    }
  };

  addToBag = () => {
    if (
      Auth.getUserDetails() !== undefined &&
      Auth.getUserDetails() !== null &&
      Auth.getToken() !== undefined
    ) {
      this.props
        .postCart(
          this.state.id || this.props.location.pathname.split("/").slice(-1)[0]
        )
        .then((res) => {
          console.log(res);
        });
    } else {
      this.setState({ modalShow: true });
    }
  };

  productInCart = () => {
    let available = false;
    return available;
  };

  render() {
    return (
      <div className="container single_product_container">
        {this.props.product && (
          <div>
            <div className="row">
              <div className="col">
              <div class="breadcrumbs d-flex flex-row align-items-center" style={ this.props.lang.lang == "en" ?  { direction: "ltr" } : { direction: "rtl"}}>
                  <ul>
                  <li>
                    <a href="/">
                      {this.props.lang.lang == "en" ? `Home` : `الصفحة الرئيسية`}
                    </a>
                  </li>
                    <li className="active">
                      <a href="/categories">
                          <i className={this.props.lang.lang == "en" ? `fa fa-angle-right` : `fa fa-angle-left`}
                          style={this.props.lang == "en" ? {  } : { marginLeft: "17px" }} aria-hidden="true"></i>
                          {this.props.lang.lang == "en" ? `Products` : `المنتجات`}
                      </a>
                    </li>
                    <li className="active">
                      <a href="#">
                          <i className={this.props.lang.lang == "en" ? `fa fa-angle-right` : `fa fa-angle-left`}
                          style={this.props.lang == "en" ? {  } : { marginLeft: "17px" }} aria-hidden="true"></i>
                          {this.props.product.en_name}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-7">
                <div className="single_product_pics">
                  <div className="row">
                    <div className="col-lg-9 image_col order-lg-2 order-1">
                      <div className="single_product_image">
                        <div
                          className="single_product_image_background"
                          style={{
                            backgroundImage: `url(${BASE_URL}/upload/${this.props.product.image})`,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="product_details">
                  <div className="product_details_title">
                    <h2>{this.props.product.title}</h2>
                    <p>{this.props.product.description}</p>
                  </div>
                  <div className="free_delivery d-flex flex-row align-items-center justify-content-center">
                    <span>
                      <i className="fas fa-truck"></i>
                    </span>
                    {/* <span>free delivery</span> */}
                  </div>
                  <div className="original_price" style={this.props.lang.lang== "en" ? { } : {direction: "rtl" , display: "flex"}}>
                          <span style={this.props.lang.lang== "ar" ? { float: "right" } : {}}>
                             {this.props.currency.currency == "dollar" ? `${this.props.product.selling_price} $` : `${this.props.product.selling_price * 250.27} YRI` }
                          </span>
                  </div>
                  <div className="unit_price" style={this.props.lang.lang== "en" ? { } : {direction: "rtl" , display: "flex"}}>
                  <span style={this.props.lang.lang== "ar" ? { float: "right" } : {}}>
                              {this.props.currency.currency == "dollar" ? `${this.props.product.selling_price} $` : `${this.props.product.selling_price * 250.27} YRI` }
                          </span>
                  </div>
                  <ul className="star_rating" style={this.props.lang.lang == "ar" ? {     direction: "rtl",
                    display: "flex",
                    float: "right",
                    width: "100%"  } : { }}>
                    <li>
                      <i className="fa fa-star" aria-hidden="true"></i>
                    </li>
                    <li>
                      <i className="fa fa-star" aria-hidden="true"></i>
                    </li>
                    <li>
                      <i className="fa fa-star" aria-hidden="true"></i>
                    </li>
                    <li>
                      <i className="fa fa-star" aria-hidden="true"></i>
                    </li>
                    <li>
                      <i className="fa fa-star-o" aria-hidden="true"></i>
                    </li>
                  </ul>
                  <div className="product_color"  style={this.props.lang.lang== "en" ? { } : {direction: "rtl", height: "5em"}}>
                    <span style={this.props.lang.lang == "ar" ? {     direction: "rtl",
                    float: "right",
                    fontWeight: "bold"  } : { fontWeight: "bold" }}>
                    
                          {this.props.lang.lang == "en" ? ` Color:` : `الألوان:`}
                    </span>
                    <ul style={this.props.lang.lang == "ar" ? {float: "right" } : {}}>
                      {
                        this.props.product.color && JSON.parse(this.props.product.color).map(e => <li style={{ background: `${e}` }}></li>) 
                      }
                    </ul>
                  </div>
                  <div  style={this.props.lang.lang== "en" ? { } : {direction: "rtl", height: "5em"}}>
                  <span style={this.props.lang.lang == "ar" ? {     direction: "rtl",
                    float: "right",
                    fontWeight: "bold"  } : { fontWeight: "bold" }}>
                          {this.props.lang.lang == "en" ? ` Description:` : `وصف المنتج:`}
                    </span>
                    <ul>
                      {
                        this.props.lang.lang == "en" ?  this.props.product.en_description :   this.props.product.ar_description 
                      }
                    </ul>
                  </div>
                  <div className="quantity d-flex flex-column flex-sm-row align-items-sm-center" style={this.props.lang.lang== "en" ? { } : {direction: "rtl", marginTop: "5em"}}>
                  <span style={this.props.lang.lang == "ar" ? {     direction: "rtl",
                    float: "right",
                    fontWeight: "bold"  } : { fontWeight: "bold" }}>
                          {this.props.lang.lang == "en" ? ` Quantity:` : `الكمية:`}
                    </span>
                    <div className="quantity_selector">
                      <span
                        className={
                          this.state.quantity > 1 ? "minus" : "minus disabled"
                        }
                        onClick={() => this.onRemoveClicked()}
                      >
                        <i className="fa fa-minus" aria-hidden="true"></i>
                      </span>
                      <span id="quantity_value">{this.state.quantity}</span>
                      <span
                        className="plus"
                        onClick={() => this.onAddClicked()}
                      >
                        <i className="fa fa-plus" aria-hidden="true"></i>
                      </span>
                    </div>
                    <div
                      className="red_button product-add_to_cart_button"
                      onClick={this.addToBag}
                    >
                      <a href="#">
                      {this.props.lang.lang == "en" ? ` add to cart` : `إضافة إلى السلة`}

                      </a>
                    </div>
                    <div onClick={() => this.addToFav(this.props.product.id)} className="product_favorite d-flex flex-column align-items-center justify-content-center">
                      <i className="far fa-heart" style={this.state.fav ? {color: 'red'} : {}}></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <LoginRegister
          show={this.state.modalShow}
          login={this.state.login}
          registerClicked={() => this.registerClicked()}
          loginClicked={() => this.loginClicked()}
          onHide={() => this.showHideModal()}
        />
      </div>
    );
  }
}

export default SingleProduct;
