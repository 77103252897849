import api from './API';

export default class CartService {
    static async CheckOut(payload, token) {
        return api.post('/orders/check-out', payload, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        }).then(resp => {
            if (resp.data.success)
                return resp.data.data
        }).catch((error) => {
            console.log("Api Error:", error);
            throw error;
        });
    }
}