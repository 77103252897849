import API from "../axios/API";
import Auth from "../modules/Auth";
import LoginService from "../axios/LoginService";

export const login = async (email, password) => {
  return LoginService.Login(email, password)
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
};
export const register = async (name, email, password, v_pasword, address) => {
  return LoginService.Register(name, email, password, v_pasword, address)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      console.error(error);
    });
};
