import React, {Component} from 'react';
import {Modal, Button} from 'react-bootstrap';
import './style.css';
import Auth from '../../modules/Auth';
import EmptyCart from '../../assets/images/emptyCart.png';
import jumpTo from '../../modules/Navigation';
import { connect } from "react-redux";

class HomeCartView extends Component {
  constructor (props) {
    super (props);
    this.state = {
      cart: {}
    };
  }
  goToChechout = () => {
    jumpTo ('/cart');
  };
  render () {
    console.log('state',this.props.cart);
    const products = this.props.cart.cart.products;
    const total = this.props.cart.cart.total;
    const totalQty = this.props.cart.cart.totalQty;
    return (
      <Modal {...this.props} className="right">
        <Modal.Header closeButton>
          <Modal.Title>
            {this.props.lang == "en" ? `Your Cart` : `سلة المنتجات`}
          </Modal.Title>
          {products !== undefined && products !== null
            ? <span
                className="checkout--btn"
                onClick={() => this.goToChechout ()}
              >
                {this.props.lang == 'en' ? `checkout` : `تأكيد الطلب`}
              </span>
            : null}
        </Modal.Header>
        <Modal.Body className="modal-body-content">
          {Auth.getUserDetails () !== undefined &&
            Auth.getUserDetails () !== null &&
            Auth.getToken () !== undefined
            ? <div>
                {products !== undefined && products !== null
                  ? null
                  : <div className="empty--basket">
                      <img src={EmptyCart} className="img-fluid" />
                      <h4 style={{textAlign: 'center'}}>
                        {this.props.lang == "en" ? `Empty cart` : `السلة فارغة`}
                      </h4>
                    </div>}
              </div>
            : <div className="empty--basket">
                <h4>
                  {this.props.lang == "en" ? `Please login to view cart` : `الرجاء تسجيل الدخول لعرض السلة`}
                </h4>
                <img src={EmptyCart} className="img-fluid" />
              </div>}

          {products !== undefined &&
            products !== null &&
            Object.keys (products).map (id => {
              return (
                <div key={id} className="basket--item">
                  <div className="basket--item--img">
                    <img src={products[id].image} alt="" />
                  </div>
                  <div className="basket--item--details">
                    <div className="basket--item--title">
                      {products[id].name}
                    </div>
                    <div className="basket--item--quantity">
                      <span>{this.props.lang == "en" ? `Quantity: ` : ' الكمية: '}</span>
                      <span>{products[id].count}</span>
                    </div>
                    <div className="basket--item--price">
                    <span>{this.props.lang == "en" ? `Price: ` : ' السعر: '}</span>
                    <span>${products[id].price}</span>
                    </div>
                    <div className="basket--item--price">
                    <span>{this.props.lang == "en" ? ` SubTotal: ` : ' المجموع الجزئي: '}</span>
                     <span>
                        { this.props.currency == "dollar" ? `$${products[id].subTotal}` : `${(products[id].subTotal * 235.17).toFixed(2)} YRI` }
                     </span>
                    </div>
                  </div>
                </div>
              );
            })}
          {products !== undefined &&
            products !== null &&
            <div className="total--price-container">
              <h3 style={{textAlign: 'center'}}>
                <span>
                <span>{this.props.lang == "en" ? ` Total: ` : ' المجموع: '}</span>
                </span>
                <span style={{color: '#FE4C50'}}>
                    { this.props.currency == "dollar" ? `$${total}` : `${(total * 235.17).toFixed(2)} YRI` }
                </span>
              </h3>
              <button
                className="btn btn-wide log-btn"
                style={{marginTop: 20}}
                onClick={() => this.goToChechout ()}
              >
                  <span>{this.props.lang == "en" ? `Checkout ` : ' تأكيد '}</span>
              </button>
            </div>}
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStoreToProps = state => ({
  cart: state.cart
});
const mapDispatchToProps = dispatch => ({
  // getAllProducts: () => dispatch(getAllProducts()),
  // applyFilters: filter_string => dispatch(applyFilters(filter_string)),
  // postCart: (productId,count,color, price, image) => dispatch(postCart(productId, count, color, price, image))
});

export default connect(mapStoreToProps, mapDispatchToProps)(HomeCartView);

