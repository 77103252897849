import React, { useEffect, useState } from "react";
import CategoryService from "../../axios/CategoryService";
import { BASE_URL } from "../../base";
import Heading from "../Heading";
import { Link } from "react-router-dom/cjs/react-router-dom";

function CategoryBanner(props) {
  const [categories, setCategories] = useState([]);
  const [searchedCats, setSearchedCats] = useState([]);
  useEffect(() => {
    CategoryService.GetCategories().then((result) => {
      setCategories(result);
      setSearchedCats(result);
    });
  }, []);
  const searchCats = (event) => {
    let _val = event.target.value;
    let _cats = [];
    if (!!_val) {
      if (props.lang == "en") {
        _cats = categories?.filter((e) =>
          e.name.toLowerCase().includes(_val.toLowerCase())
        );
        setSearchedCats(_cats);
      } else {
        _cats = categories?.filter((e) =>
          e.name_a.toLowerCase().includes(_val.toLowerCase())
        );
        setSearchedCats(_cats);
      }
    } else setSearchedCats(categories);
  };
  return (
    <div className="banner">
      <div className="container">
        <div className="row my-5">
          <Heading
            title={props.lang == "en" ? "Categories" : "الأصناف"}
            data-aos="fade-up"
          />
        </div>
        <div className="row my-3">
          <input
            onChange={searchCats}
            className="form-control"
            placeholder={
              props.lang == "en" ? "Search Categories...." : "بحث عن صنف..."
            }
            style={
              props.lang == "en"
                ? { direction: "ltr", backgroundColor: "beige" }
                : { direction: "rtl", backgroundColor: "beige" }
            }
          />
        </div>
        <div className="row">
          {searchedCats.map((cat, index) => {
            return (
              <div
                key={index}
                className="col-md-4"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "5em",
                }}
              >
                <div
                  className="banner_item align-items-center"
                  style={{
                    width: "10em",
                    height: "10em",
                    backgroundImage: `url(${BASE_URL}/upload/${cat.image})`,
                  }}
                  data-aos="fade-right"
                >
                  <div className="banner_category">
                    <Link to="/categories">
                      {props.lang == "en"
                        ? `${cat.name} (${cat.product_count})`
                        : `${cat.name_a} (${cat.product_count})`}
                    </Link>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default CategoryBanner;
