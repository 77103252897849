

import React, { Component } from "react";
import SingleProduct from "../../components/Products/SingleProduct";
import Auth from "../../modules/Auth";
import LoginRegister from "../../components/LoginRegisterModal";
import Filter from "./components/Filter";
import { toast } from "react-toastify";
import { Link } from "react-router-dom/cjs/react-router-dom";

class Category extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: this.props.categories,
      products: this.props.products,
      filteredProducts: this.props.products,
      currency: props.currency.currency,
      modalShow: false,
      login: true,
      active: -1,
    };
    this.addToBag = this.addToBag.bind(this);
    this.filterByCategory = this.filterByCategory.bind(this);
  }
  componentDidMount() {
    if (!this.props.categories) {
      this.props.getAllCategories();
    }
    if (!this.props.products) {
      this.props.getAllProducts();
    }
  }
  showHideModal = () => {
    this.setState({ modalShow: false });
  };

  loginClicked = () => {
    this.setState({ modalShow: true, login: true });
  };
  registerClicked = () => {
    this.setState({ modalShow: true, login: false });
  };

  addToBag =  (id,count, color, price, image, name) => {
    if (
      Auth.getUserDetails() !== undefined &&
      Auth.getUserDetails() !== null &&
      Auth.getToken() !== undefined
    ) {
       if(color == '')
       this.props.lang.lang == "en" ? toast.error('you must select color') : toast.error('يجب تحديد لون المنتج الذي تريده')
       else {
        let cart = this.props.postCart(id,count, color, price, image, name);
        this.props.lang.lang == "en" ? toast.success('Product added to cart successfully') 
        : toast.success('تم إضافة المنتج بنجاح إلى السلة')
      }
    } else {
      this.setState({ modalShow: true });
    }
  };
  componentDidUpdate(prevProps, prevState) {
    if(prevProps.products != this.props.products)
    this.setState({
      products: this.props.products,
      filteredProducts: this.props.products
    })
  }
  filterByCategory(id) 
  {
    if(id !== 0)
      this.setState({
        filteredProducts: this.state.products.filter(e => e.category_id == id),
        active: id,
      })
    else
      this.setState({
        filteredProducts: this.state.products,
        active: id,
      }) 
  }
  render() {
    const { categories, products, applyFilters } = this.props;
    console.log('props', this.props);
    return (
      <div className="container product_section_container">
        <div className="row">
          <div className="col product_section clearfix">
            <div class="breadcrumbs d-flex flex-row align-items-center" style={ this.props.lang.lang == "en" ?  { direction: "ltr" } : { direction: "rtl"}}>
              <ul>
                <li>
                  <a href="/">
                    {this.props.lang.lang == "en" ? `Home` : `الصفحة الرئيسية`}
                  </a>
                </li>
                <li class="active">
                <Link to="/categories">
                    <i className={this.props.lang.lang == "en" ? `fa fa-angle-right` : `fa fa-angle-left`}
                          style={this.props.lang == "en" ? {  } : { marginLeft: "17px" }} aria-hidden="true"></i>
                    {this.props.lang.lang == "en" ? `Categories` : `الأصناف`}
                  </Link>
                </li>
              </ul>
            </div>
            <div className="sidebar" style={this.props.lang.lang == "en" ? { float: "left",
                                                                            width: "200px",
                                                                            paddingRight: "17px"} : {
                                                                            float: "right",
                                                                            width: "200px",
                                                                            paddingLeft: "17px"
                                                                            }}>
              <Filter 
               lang={this.props.lang.lang}
               categories={categories}
               active={this.state.active}
               filterByCategory={this.filterByCategory} applyFilters={applyFilters} />
            </div>
            <div className="main_content">
              <div class="products_iso">
                <div className="row">
                  {this.state.filteredProducts &&
                    this.state.filteredProducts.map((item, index) => {
                      return (
                        <div
                          className="col-lg-3 col-sm-6"
                          key={index}
                          data-aos="zoom-in"
                        >
                          <SingleProduct
                           currency={this.props.currency.currency}
                            lang={this.props.lang.lang}
                            productItem={item}
                            addToBag={this.addToBag}
                          />
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <LoginRegister
          show={this.state.modalShow}
          login={this.state.login}
          registerClicked={() => this.registerClicked()}
          loginClicked={() => this.loginClicked()}
          onHide={() => this.showHideModal()}
        />
      </div>
    );
  }
}

export default Category;
