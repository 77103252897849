import React, { Component } from "react";
import { Link } from "react-router-dom";
import HomeCartView from "../HomeCartView";
import MobileMenu from "../MobileMenu";
import device, { size } from "../../modules/mediaQuery";
import MediaQuery from "react-responsive";
import Auth from "../../modules/Auth";
import LoginRegister from "../LoginRegisterModal";
import logo from "../../assets/images/logo_bg.jpg";
class NavBar extends Component {
  constructor(props) {
    super(props);
    console.log(this.props);
    this.state = {
      modalShow: false,
      loginShow: false,
      activeclass: false,
      lang: this.props.lang.lang,
      currency: this.props.currency.currency,
    };
    console.log(this.state);
  }
  componentDidMount() {
    if (Object.keys(this.props.cart).length < 1) {
      // this.props.getCartByUserId();
    }
  }

  showHideModal = () => {
    this.setState({ modalShow: !this.state.modalShow });
  };
  showLoginHideModal = () => {
    this.setState({ loginShow: !this.state.loginShow });
  };
  handleMenuClicked = () => {
    this.setState({ activeclass: !this.state.activeclass });
  };
  logout = (e) => {
    e.preventDefault();
    Auth.logout();
    window.location.href = "/";
  };
  loginClicked = () => {
    this.setState({ loginShow: true, login: true });
  };
  registerClicked = () => {
    this.setState({ loginShow: true, login: false });
  };
  setLanguage = (payload) => {
    this.setState({ lang: payload });
    this.props.setLang(payload);
  };
  setCurrency = (payload) => {
    this.setState({ currency: payload });
    this.props.setCurrency(payload);
  };
  render() {
    const cart = this.props.cart;
    console.log("nav", this.props);
    return (
      <div className="main_nav_container">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-right">
              <div className="logo_container">
                <Link to="/">
                  <img src={logo} className="logo-img" />
                </Link>
              </div>
              <nav className="navbar">
                <ul
                  className="navbar_menu"
                  style={
                    this.state.lang == "en"
                      ? { direction: "ltr" }
                      : { direction: "rtl" }
                  }
                >
                  <li>
                    <Link to="/home">
                      {this.state.lang == "en" ? "home" : "الصفحة الرئيسية"}
                    </Link>
                  </li>
                  <li>
                    <Link to="/categories">
                      {this.state.lang == "en" ? "products" : "المنتجات"}
                    </Link>
                  </li>
                  <li>
                    <Link to="/categories">
                      {this.state.lang == "en" ? "categories" : "الأصناف"}
                    </Link>
                  </li>
                  <li className="mega-drop-down">
                    <a href="#">
                      {this.state.lang == "en" ? "Language" : "اللغة"}

                      <i className="fa fa-angle-down" />
                    </a>
                    <div className="mega-menu">
                      <div className="mega-menu-wrap">
                        <div className="mega-menu-content">
                          <ul className="stander">
                            <li key="english">
                              <a
                                onClick={this.setLanguage.bind(this, "en")}
                                className={
                                  this.state.lang == "en" ? "active" : ""
                                }
                                href="#"
                              >
                                English
                              </a>
                            </li>
                            <li key="arabic">
                              <a
                                onClick={this.setLanguage.bind(this, "ar")}
                                className={
                                  this.state.lang == "ar" ? "active" : ""
                                }
                                href="#"
                              >
                                العربية
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </li>
                  {/* <li className="mega-drop-down">
                    <a href="#">
                      {this.state.lang == "en" ? "Currency" : "العملة"}

                      <i className="fa fa-angle-down" />
                    </a>
                    <div className="mega-menu">
                      <div className="mega-menu-wrap">
                        <div className="mega-menu-content">
                          <ul className="stander">
                            <li key="dollar">
                              <a
                                onClick={this.setCurrency.bind(this, "dollar")}
                                className={
                                  this.state.currency == "dollar"
                                    ? "active"
                                    : ""
                                }
                                href="#"
                              >
                                Dollar ($)
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </li> */}
                  {Auth.getUserDetails() !== undefined &&
                    Auth.getUserDetails() !== null &&
                    Auth.getToken() !== undefined && (
                      <li className="mega-drop-down">
                        <a href="#">
                          {this.state.lang == "en"
                            ? `Welcome ${Auth.getUserDetails().name}`
                            : `مرحباً ${Auth.getUserDetails().name}`}
                        </a>
                        <div className="mega-menu">
                          <div className="mega-menu-wrap">
                            <div className="mega-menu-content">
                              <ul className="stander">
                                <li>
                                  <a href="#" onClick={(e) => this.logout(e)}>
                                    <i
                                      className="fas fa-sign-in-alt mx-1"
                                      aria-hidden="true"
                                    />
                                    {this.state.lang == "en"
                                      ? "Logout"
                                      : "تسجيل الخروج"}
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </li>
                    )}
                  {(Auth.getUserDetails() === undefined ||
                    Auth.getUserDetails() === null ||
                    Auth.getToken() === undefined) && (
                    <li>
                      <a href="#" onClick={() => this.loginClicked()}>
                        {this.state.lang == "en" ? "Login" : "تسجيل الدخول"}
                      </a>
                    </li>
                  )}
                </ul>
                <ul className="navbar_user">
                  <li className="checkout">
                    <a href="#" onClick={() => this.showHideModal()}>
                      <i className="fas fa-shopping-bag" />
                      {cart.totalQty !== undefined && (
                        <span id="checkout_items" className="checkout_items">
                          {cart.totalQty}
                        </span>
                      )}
                    </a>
                  </li>
                </ul>
                <div
                  className="hamburger_container"
                  onClick={() => this.handleMenuClicked()}
                >
                  <i className="fa fa-bars" aria-hidden="true" />
                </div>
              </nav>
            </div>
          </div>
        </div>
        <MediaQuery query={device.max.tabletL}>
          <MobileMenu
            loginClicked={this.loginClicked}
            logout={this.logout}
            setCurrency={this.setCurrency}
            setLanguage={this.setLanguage}
            lang={this.props.lang}
            currency={this.props.currency}
            activeClass={this.state.activeclass}
            onClose={() => this.handleMenuClicked()}
          />
        </MediaQuery>
        {this.state.loginShow ? (
          <LoginRegister
            lang={this.props.lang.lang}
            show={this.state.loginShow}
            login={this.state.login}
            registerClicked={() => this.registerClicked()}
            loginClicked={() => this.loginClicked()}
            onHide={() => this.showLoginHideModal()}
          />
        ) : null}
        {this.state.modalShow ? (
          <HomeCartView
            currency={this.props.currency.currency}
            lang={this.props.lang.lang}
            cart={cart}
            show={this.state.modalShow}
            onHide={() => this.showHideModal()}
          />
        ) : null}
      </div>
    );
  }
}

export default NavBar;
