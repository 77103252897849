

import { connect } from "react-redux";
import Cart from "./Cart";
import { getCartByUserId, postCart, clearCart } from "../../redux/actions/cartAction";

const mapStoreToProps = (state) => ({
  cart: state.cart,
  lang: state.lang,
  currency: state.currency
});
const mapDispatchToProps = (dispatch) => ({
  // getCartByUserId: dispatch(getCartByUserId()),
  postCart: (productId, count, _color, price, image, name) =>
  dispatch(postCart (productId, count, _color, price, image, name)),
  clearCart: () => dispatch(clearCart())
});

export default connect(mapStoreToProps, mapDispatchToProps)(Cart);
