import api from './API';

export default class SliderService {
    static async GetSlider() {
        return api.get('/slider').then(resp => {
            if (resp?.data?.success)
                return resp.data.data;
        }).catch((error) => {
            console.log("Api Error:", error);
            throw error;
        });
    }
}