import React, { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import SliderService from "../../axios/SliderService";
import { BASE_URL } from "../../base";

function HomeBanner(props) {
  const [sliders, setSliders] = useState([]);
  useEffect(() => {
    SliderService.GetSlider().then((result) => {
      setSliders(result);
    });
  }, []);
  return !!sliders && sliders.length > 0 ? (
    <Carousel>
      {sliders.map((slide, index) => {
        return (
          <Carousel.Item>
            <div
              key={index}
              className="d-block w-100 main_slider"
              style={{
                backgroundImage: `url(${BASE_URL}/upload/${slide.image})`,
              }}
            >
              <div className="container fill_height">
                <div className="row align-items-center fill_height">
                  <div className="col">
                    <div className="main_slider_content" data-aos="fade-right">
                      <h6>{slide.en_title}</h6>
                      <h1>{slide.en_title1}</h1>
                      <div className="red_button shop_now_button">
                        <a href="#">shop now</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>
        );
      })}
    </Carousel>
  ) : (
    <> </>
  );
}

export default HomeBanner;
