import {
  combineReducers
} from "redux";
import login from "./LoginReducer";
import register from "./RegisterReducer";
import department from "./DepartmentReducer";
import product from "./productReducer";
import variant from "./variantsReducer";
import cart from "./cartReducer";
import category from './categoryReducer'
import lang from "./langReducer";
import currency from './CurrencyReducer';
// import checkout from './checkoutReducer'
// import filter from './filterReducer'

export default combineReducers({
  department,
  login,
  register,
  product,
  category,
  variant,
  cart,
  lang,
  currency
});